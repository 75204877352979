import { create } from "zustand";

interface PaymentLoadingState {
  isLoading: boolean;
  paymentFormSubmitting: boolean;
  verifyingRedirectResult: boolean;
  setPaymentFormSubmitting: (paymentFormSubmitting: boolean) => void;
  setVerifyingRedirectResult: (verifyingRedirectResult: boolean) => void;
  setIsLoading: (isLoading: boolean) => void;
}

export const usePaymentLoadingStore = create<PaymentLoadingState>(set => ({
  isLoading: false,
  paymentFormSubmitting: false,
  verifyingRedirectResult: false,
  setVerifyingRedirectResult: verifyingRedirectResult =>
    set({ verifyingRedirectResult }),
  setPaymentFormSubmitting: paymentFormSubmitting =>
    set({ paymentFormSubmitting }),
  setIsLoading: isLoading => set({ isLoading }),
}));
