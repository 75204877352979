import { getDateTimeFormatter, getRelativeTimeFormatter } from "./intl";

interface BaseOptions {
  date: Date;
  locale: MatchiLocale;
  timeZone?: string;
}

interface FormatISOOptions extends Omit<BaseOptions, "locale"> {
  representation?: "datetime" | "date";
}

interface FormatDurationOptions extends BaseOptions {
  date2: Date;
}

export const formatISO = ({
  date, // TODO: Accept strings and number, convert to Date, return 'N/A' if invalid
  timeZone,
  representation = "datetime",
}: FormatISOOptions) => {
  const isDateTime = representation === "datetime";
  const timeValue = isDateTime ? "numeric" : undefined;

  const formatter = getDateTimeFormatter("sv-SE", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: timeValue,
    minute: timeValue,
    second: timeValue,
    timeZone,
  });

  return `${formatter.format(date).replace(" ", "T")}${isDateTime ? "Z" : ""}`;
};

export const formatHours = ({
  date, // TODO: Accept strings and number, convert to Date, return 'N/A' if invalid
  locale,
  timeZone,
}: BaseOptions) => {
  const formatter = getDateTimeFormatter(locale, {
    hour: "numeric",
    minute: "numeric",
    timeZone,
  });

  return formatter.format(date);
};

export const formatDuration = ({
  date, // TODO: Accept strings and number, convert to Date, return 'N/A' if invalid
  date2, // TODO: Accept strings and number, convert to Date, return 'N/A' if invalid
  locale,
}: FormatDurationOptions) => {
  const diff = Math.floor((date2.valueOf() - date.valueOf()) / (1000 * 60));
  const formatter = getRelativeTimeFormatter(locale, { numeric: "auto" });
  const [_, integer, literal] = formatter.formatToParts(diff, "minute");

  return integer.value + literal.value;
};

export const formatFullDate = ({ date, locale, timeZone }: BaseOptions) => {
  const formatter = getDateTimeFormatter(locale, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone,
  });

  return formatter.format(date);
};

export const formatDateSummary = ({ date, locale, timeZone }: BaseOptions) => {
  const formatter = getDateTimeFormatter(locale, {
    weekday: "short",
    day: "numeric",
    month: "long",
    year: "numeric",
    timeZone,
  });

  return formatter.format(date);
};
