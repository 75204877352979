const CACHE: Map<string, Intl.NumberFormat> = new Map();

export const getNumberFormatter = (
  locale: MatchiLocale,
  options?: Intl.NumberFormatOptions,
) => {
  let key = locale + JSON.stringify(options);
  let formatter = CACHE.get(key);

  if (!formatter) {
    formatter = new Intl.NumberFormat(locale, options);
    CACHE.set(key, formatter);
  }

  return formatter;
};
