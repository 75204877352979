import { motion } from "framer-motion";
import { useState } from "react";

type ImageProps = {
  src?: string;
  alt?: string;
  width?: string | number;
  height?: string | number;
  className?: string;
};

export const Image = ({ src, alt, width, height, className }: ImageProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isInView, setIsInView] = useState(false);

  return (
    <motion.img
      className={className}
      src={src}
      alt={alt}
      width={width}
      height={height}
      initial={false}
      animate={isLoaded && isInView ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.2 }}
      viewport={{ once: true }}
      onViewportEnter={() => setIsInView(true)}
      onLoad={() => setIsLoaded(true)}
    />
  );
};

Image.displayName = "Image";
