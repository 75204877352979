import { forwardRef } from "react";
import * as Icons from "./generated";
import clsx from "clsx";

export type IconProps = {
  icon: keyof typeof Icons;
  className?: string;
};

const styles = {
  base: "text-base text-black inline-flex",
};

export const Icon = forwardRef<HTMLDivElement, IconProps>(
  ({ icon, className }, ref) => {
    const Component = Icons[icon];

    return (
      <div
        ref={ref}
        role="presentation"
        className={clsx(styles.base, className)}
      >
        {Component && <Component />}
      </div>
    );
  },
);

Icon.displayName = "Icon";
