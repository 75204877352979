import clsx from "clsx";
import { forwardRef } from "react";

export type LoadingProps = {
  size?: "sm" | "md" | "lg";
  className?: string;
};

const styles = {
  base: "",
  size: {
    sm: "w-4 h-4",
    md: "w-6 h-6",
    lg: "w-8 h-8",
  },
};

export const Loading = forwardRef<SVGSVGElement, LoadingProps>(
  ({ size = "md", className = "text-theme-accent" }, ref) => {
    return (
      <svg
        role="status"
        className={clsx([styles.size[size], className, "animate-spin"])}
        ref={ref}
        viewBox="0 0 24 24"
      >
        <path
          d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM2.4 12a9.6 9.6 0 1 0 19.2 0 9.6 9.6 0 0 0-19.2 0Z"
          fill="url(#path)"
        />
        <radialGradient
          id="path"
          cx={0}
          cy={0}
          r={1.25}
          gradientTransform="matrix(0 1 -4 0 4 0)"
        >
          <stop stopOpacity={0} />
          <stop offset={0.469} stopColor="currentcolor" />
          <stop offset={0.891} stopColor="currentcolor" stopOpacity={0} />
        </radialGradient>
      </svg>
    );
  },
);
