import clsx from "clsx";
import { forwardRef } from "react";
import { Icon, IconProps } from "../Icon";

const styles = {
  base: "font-medium inline-flex items-center space-x-1.5 underline outline-none focus-visible:ring-2 ring-offset-1 ring-theme-accent transition duration-150 ease-in-out",
  variants: {
    primary:
      "text-theme-link-primary-text-default hover:text-theme-link-primary-text-hover",
    secondary: "text-gray-1200 hover:text-gray-1100",
  },
};

const iconStyles = {
  base: "text-xs",
  variant: {
    primary: "text-theme-link-primary-text-default",
    secondary: "text-gray-1200",
  },
};

type LinkProps = {
  children?: React.ReactNode;
  variant?: "primary" | "secondary";
  leadingIcon?: IconProps["icon"];
  trailingIcon?: IconProps["icon"];
} & Pick<JSX.IntrinsicElements["a"], "href" | "target" | "rel">;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      variant = "primary",
      leadingIcon,
      trailingIcon,
      href,
      target,
      rel,
    },
    ref,
  ) => {
    return (
      <a
        href={href}
        target={target}
        rel={rel}
        ref={ref}
        className={clsx([styles.base, styles.variants[variant]])}
      >
        {leadingIcon && (
          <Icon
            className={clsx([iconStyles.base, iconStyles.variant[variant]])}
            icon={leadingIcon}
          />
        )}
        <span>{children}</span>
        {trailingIcon && (
          <Icon
            className={clsx([iconStyles.base, iconStyles.variant[variant]])}
            icon={trailingIcon}
          />
        )}
      </a>
    );
  },
);

Link.displayName = "Link";
