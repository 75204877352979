import * as RadixRadioGroup from "@radix-ui/react-radio-group";
import { withClassName } from "../../utils";

const Root = RadixRadioGroup.Root;
const Item = withClassName(
  RadixRadioGroup.Item,
  "flex relative items-center justify-center font-medium text-gray-1200 h-6 w-6 rounded-full bg-white disabled:bg-gray-400 border border-default hover:border-heavy outline-none focus-visible:ring-2 ring-offset-1 ring-theme-accent transition duration-150 ease-in-out",
);

const Indicator = withClassName(
  RadixRadioGroup.Indicator,
  "h-4 w-4 rounded-full transition duration-150 ease-in-out bg-theme-accent",
);

export const RadioGroup = {
  Root,
  Item,
  Indicator,
};
