import { Image } from "ui";
import { PaymentMethod } from "@adyen/adyen-web/dist/types/types";

interface PaymentMethodIconProps {
  type: PaymentType;
  method?: PaymentMethod;
}

export const PaymentMethodIcon = ({ type, method }: PaymentMethodIconProps) => {
  // Internal methods
  if (type === "COUPON") {
    return (
      <div className="h-[26px] w-[40px]">
        <MatchiPaymentIcon />
      </div>
    );
  }

  // Saved credit card
  if (method?.type === "scheme" && typeof method?.brand === "string") {
    return (
      <Image
        className="h-[26px] w-[40px]"
        alt={method.brand}
        src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${method.brand}.svg`}
      />
    );
  }

  // New credit card
  if (method?.type === "scheme" && Array.isArray(method?.brands)) {
    const firstThree = method.brands.slice(0, 3);
    const rest = method.brands.length - 3;
    return (
      <div className="flex items-center space-x-1.5">
        {firstThree.map(brand => (
          <Image
            key={brand}
            className="border-light h-[26px] w-[40px] rounded border"
            alt={brand}
            src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${brand}.svg`}
          />
        ))}
        <span className="text-center text-sm font-medium">+{rest}</span>
      </div>
    );
  }

  // Apple pay
  if (typeof method?.type === "string") {
    return (
      <Image
        className="h-[26px] w-[40px]"
        alt={method.type}
        src={`https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/${method.type}.svg`}
      />
    );
  }

  return null;
};

const MatchiPaymentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 26">
      <g filter="url(#a)">
        <rect width="40" height="26" fill="#22AD5C" rx="4" />
        <path
          fill="#fff"
          d="M23.836 12.978v5.904c0 .988.87 1.793 1.87 1.601.761-.146 1.294-.855 1.294-1.644V8.016l-3.164 4.962ZM25.4 5.488h-.342c-.643 0-1.097.276-1.429.826l-3.869 6.433-3.846-6.412c-.29-.488-.767-.847-1.449-.847h-.42c-.739 0-1.358.507-1.543 1.195-.044.138-.06.428-.06.744v11.497c0 .943.78 1.674 1.736 1.578.789-.077 1.428-.836 1.428-1.641v-7.307l2.765 4.335c.332.507.75.823 1.329.823.58 0 .997-.316 1.329-.823l5.903-9.248A1.609 1.609 0 0 0 25.4 5.488Z"
        />
      </g>
      <defs>
        <filter
          id="a"
          width="40"
          height="26"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
          <feBlend in2="shape" result="effect1_innerShadow_895_2904" />
        </filter>
      </defs>
    </svg>
  );
};
