export const getQueryParam = (param: string) => {
  if (typeof window === undefined) return null;
  const searchParams = new URL(window.location?.href).searchParams;
  return searchParams.get(param);
};

export const removeQueryParam = (param: string) => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  params.delete(param);

  let path = window.location.pathname;

  if (Array.from(params.entries()).length) {
    path = path + "?" + params.toString();
  }

  return window.history.replaceState({}, window.document.title, path);
};
