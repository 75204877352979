/// <reference path="../../types/global.d.ts" />

import Keycloak, { KeycloakConfig, KeycloakInitOptions } from "keycloak-js";

export interface Options {
  config: KeycloakConfig;
  options?: KeycloakInitOptions;
}

let _keycloak: Keycloak | null = null;

const defaultOptions: Partial<KeycloakInitOptions> = {
  pkceMethod: "S256",
  onLoad: "check-sso",
};

const init = async ({ config, options = {} }: Options) => {
  _keycloak = new Keycloak(config);

  try {
    const authenticated = await _keycloak.init({
      ...defaultOptions,
      ...options,
    });

    return authenticated;
  } catch (error) {
    console.error("Failed to initialize Keycloak: ", error);
  }
};

const login = () => _keycloak?.login();
const logout = () => _keycloak?.logout();
const getToken = () => _keycloak?.token;
const getTokenParsed = () => _keycloak?.tokenParsed;
const getIdToken = () => _keycloak?.idToken;
const getIdTokenParsed = () => _keycloak?.idTokenParsed;
const getLocale = () =>
  _keycloak?.tokenParsed?.locale as KeycloakLocale | undefined;
const isAuthenticated = () => Boolean(_keycloak?.authenticated);
const isTokenExpired = (minValidity?: number) =>
  _keycloak?.isTokenExpired(minValidity);

export const auth = {
  init,
  login,
  logout,
  getLocale,
  getToken,
  getTokenParsed,
  getIdToken,
  getIdTokenParsed,
  isAuthenticated,
  isTokenExpired,
};
