import { useSyncExternalStore } from "react";

interface PaymentServiceEventsOptions {
  [key: string]: (e: any) => void;
}

// TODO: Type this properly
export const emitPaymentServiceEvent = (type: any, detail: any) => {
  const event = new CustomEvent(type, { detail });
  window.dispatchEvent(event);
};

export const usePaymentServiceEvents = (opts: PaymentServiceEventsOptions) => {
  const subscribe = () => {
    window.addEventListener("onPaymentCompleted", opts.onPaymentCompleted);
    window.addEventListener("onError", opts.onError);

    return () => {
      window.removeEventListener("onPaymentCompleted", opts.onPaymentCompleted);
      window.removeEventListener("onError", opts.onError);
    };
  };

  return useSyncExternalStore(subscribe, () => {});
};
