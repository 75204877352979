import { PaymentOption } from "@/components";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";
import {
  InternalPaymentOption,
  MixedPaymentOption,
  PaymentServiceOption,
} from "@/queries";
import { zodResolver } from "@hookform/resolvers/zod";
import { formatCurrency } from "format-numbers";
import { T, keycloakToMatchiLocale } from "i18n";
import { checkoutResponse } from "matchi-api";
import { auth } from "matchi-auth";
import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Button, Icon, Link, RadioGroup } from "ui";
import { z } from "zod";

interface PaymentFormProps {
  onSubmit: SubmitHandler<ValidationSchema>;
  checkout: checkoutResponse;
  paymentOptions: MixedPaymentOption[];
}

const validationSchema = z.object({
  paymentOptionId: z.string(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

export const PaymentForm = ({
  onSubmit,
  checkout,
  paymentOptions,
}: PaymentFormProps) => {
  const { verifyingRedirectResult, paymentFormSubmitting } =
    usePaymentLoadingStore(state => ({
      paymentFormSubmitting: state.paymentFormSubmitting,
      verifyingRedirectResult: state.verifyingRedirectResult,
    }));

  const getDefaultValues = () => ({
    paymentOptionId: checkout.isFree
      ? "FREE"
      : paymentOptions.find(({ id, disabled }) => id && !disabled)?.id,
  });

  const { handleSubmit, watch, control, reset } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: getDefaultValues(),
  });

  useEffect(() => {
    reset(getDefaultValues());
  }, [paymentOptions]);

  const selectedPaymentOptionId = watch("paymentOptionId");
  const selectedPaymentOption = paymentOptions.find(
    ({ id }) => id === selectedPaymentOptionId,
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={verifyingRedirectResult ? "pointer-events-none" : ""}
    >
      {!checkout.isFree && (
        <div className="mb-2">
          <Controller
            control={control}
            name="paymentOptionId"
            render={({ field, fieldState }) => (
              <>
                <RadioGroup.Root
                  asChild
                  disabled={paymentFormSubmitting || verifyingRedirectResult}
                  value={field.value}
                  onValueChange={field.onChange}
                >
                  <div className="border-default mb-4 rounded-xl border">
                    {paymentOptions.map(option => (
                      <PaymentOption
                        key={option.id}
                        option={option}
                        selected={option.id === selectedPaymentOption?.id}
                      />
                    ))}
                  </div>
                </RadioGroup.Root>
                {fieldState.error && fieldState.error.message}
              </>
            )}
          />
        </div>
      )}
      {/**
       * TODO: We assume that if amountToPay is less than reservationAmount, it's a split booking payment.
       * This is a naive solution and the API should tell us if it's a split booking or not.
       */}
      {parseFloat(checkout.amountToPay) <
        parseFloat(checkout.reservationAmount) && (
        <div className="mb-2 rounded-xl bg-gray-300 p-4">
          <h4 className="mb-2 flex items-center space-x-2 font-medium">
            <Icon icon="CircleCheckSolid" className="text-signal-warning" />
            <span className="leading-none">
              <T _str="Important notice" />
            </span>
          </h4>
          <p>
            <T
              amount={
                <span className="font-medium">
                  {formatCurrency({
                    locale: keycloakToMatchiLocale(auth.getLocale()),
                    number: parseFloat(checkout.reservationAmount),
                    currency: checkout.price?.currency!,
                  })}
                </span>
              }
              _str="By proceeding, I agree to a reservation and a charge of {amount} to my payment method within 7 days. I understand I will be refunded an amount equal to the value of the participants' parts 2 hours after the booked session, provided they have paid their part within the specified time."
            />
          </p>
        </div>
      )}
      <div className="space-y-2">
        <Button
          loading={paymentFormSubmitting}
          disabled={paymentFormSubmitting || verifyingRedirectResult}
          block
          size="lg"
        >
          <PayButtonLabel
            checkout={checkout}
            paymentOption={selectedPaymentOption}
          />
        </Button>
        <p className="text-gray-1100 text-center leading-normal">
          <T
            _str="By clicking “{buttonLabel}” I accept the {facilityTerms} of the seller, {facilityInfo}. I also confirm having read and accepted MATCHi’s {terms} provided in the English language."
            buttonLabel={
              checkout.isFree ? <T _str="Confirm order" /> : <T _str="Pay" />
            }
            facilityInfo={
              checkout.facilityOrgNr ? (
                <span className="inline font-medium text-black">{`${
                  Boolean(checkout.facilityLegalEntityName)
                    ? checkout.facilityLegalEntityName
                    : checkout.facilityName
                }, ${checkout.facilityOrgNr}`}</span>
              ) : (
                <span className="inline font-medium text-black">
                  {Boolean(checkout.facilityLegalEntityName)
                    ? checkout.facilityLegalEntityName
                    : checkout.facilityName}
                </span>
              )
            }
            terms={
              <span className="inline">
                <Link
                  variant="secondary"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.matchi.se/home/useragreement"
                >
                  <T _str="terms" />
                </Link>
              </span>
            }
            facilityTerms={
              checkout.facilityPrivacyPolicyUrl ? (
                <span className="inline">
                  <Link
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={checkout.facilityPrivacyPolicyUrl}
                  >
                    <T _str="Terms and Conditions" />
                  </Link>
                </span>
              ) : (
                <span className="inline font-medium text-black">
                  <T _str="Terms and Conditions" />
                </span>
              )
            }
          />
        </p>
      </div>
    </form>
  );
};

const PayButtonLabel = ({
  checkout,
  paymentOption,
}: {
  checkout: checkoutResponse;
  paymentOption?: MixedPaymentOption;
}) => {
  // Free orders
  if (checkout.isFree) return <T _str="Confirm order" />;

  // Adyen gift cards
  if (
    paymentOption?.type === "PAYMENT_SERVICE" &&
    (paymentOption as PaymentServiceOption).method?.type === "giftcard"
  ) {
    return <T _str="Redeem gift card" />;
  }

  // Punch cards
  if (paymentOption?.type === "COUPON") {
    const option = paymentOption as InternalPaymentOption;
    const punchesNeeded = option.method?.punchesNeeded
      ? parseInt(option.method?.punchesNeeded)
      : 1;

    if (punchesNeeded <= 1) return <T _str="Pay with 1 punch" />;

    return (
      <T
        _str="Pay with {punchesNeeded} punches"
        punchesNeeded={punchesNeeded}
      />
    );
  }

  // Adyen payment methods
  return (
    <T
      _str="Pay {amount}"
      amount={formatCurrency({
        locale: keycloakToMatchiLocale(auth.getLocale()),
        number: parseFloat(checkout.amountToPay),
        currency: checkout.price?.currency!,
      })}
    />
  );
};
