import * as Accordion from "@radix-ui/react-accordion";
import { T } from "i18n";
import { checkoutResponse } from "matchi-api";
import { ApplyValueCard, ApplyPromoCode } from "@/components";
import { Icon } from "ui";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";

interface ApplyOffersProps {
  checkout: checkoutResponse;
}

export const ApplyOffers = ({ checkout }: ApplyOffersProps) => {
  const { verifyingRedirectResult } = usePaymentLoadingStore(state => ({
    verifyingRedirectResult: state.verifyingRedirectResult,
  }));

  const valueCardApplied = !!checkout.valueCardOutcomes.length;
  const promoCodeApplied = !!checkout.promoCodeOutcomes.length;

  const shouldRenderPromoCode = checkout.canUsePromoCode;
  const shouldRenderValueCards = !!checkout.valueCards.length;

  if (!shouldRenderPromoCode && !shouldRenderValueCards) {
    return null;
  }

  return (
    <Accordion.Root
      type="single"
      collapsible
      className="border-default mb-4 rounded-xl border"
    >
      {shouldRenderPromoCode && (
        <Accordion.Item
          value="promoCode"
          disabled={verifyingRedirectResult}
          className="border-default relative z-10 border-b last-of-type:border-b-0"
        >
          <Accordion.Trigger className="group flex w-full items-center justify-between p-4">
            <h2 className="flex items-center space-x-2 text-lg font-medium">
              <Icon icon="TagSolid" className="text-theme-accent" />
              <span className="leading-none">
                <T _str="Promo code" />
              </span>
            </h2>
            <Icon
              className="transition-transform duration-300 group-data-[state=open]:rotate-180"
              icon="ChevronDownSolid"
            />
          </Accordion.Trigger>
          <Accordion.Content className="border-default border-t">
            {valueCardApplied ? (
              <DisabledContainer>
                <T _str="Cannot be combined with value cards" />
              </DisabledContainer>
            ) : (
              <div className="p-4">
                <ApplyPromoCode disabled={promoCodeApplied} />
              </div>
            )}
          </Accordion.Content>
        </Accordion.Item>
      )}
      {shouldRenderValueCards && (
        <Accordion.Item
          value="valueCards"
          disabled={verifyingRedirectResult}
          className="border-default relative z-10 border-b last-of-type:border-b-0"
        >
          <Accordion.Trigger className="group flex w-full items-center justify-between p-4">
            <h2 className="flex items-center space-x-2 text-lg font-medium">
              <div className="bg-theme-accent flex h-4 w-4 items-center justify-center rounded">
                <span className="-mt-[1px] text-xs font-bold text-white">
                  {checkout.valueCards.length}
                </span>
              </div>
              <span className="leading-none">
                <T _str="Value cards" />
              </span>
            </h2>
            <Icon
              className="transition-transform duration-300 group-data-[state=open]:rotate-180"
              icon="ChevronDownSolid"
            />
          </Accordion.Trigger>
          <Accordion.Content className="border-default border-t">
            {promoCodeApplied ? (
              <DisabledContainer>
                <T _str="Cannot be combined with promo codes" />
              </DisabledContainer>
            ) : (
              <ul className="flex flex-col">
                {checkout.valueCards.map(valueCard => (
                  <li
                    className="border-default border-t first-of-type:border-t-0"
                    key={valueCard.id}
                  >
                    <ApplyValueCard
                      valueCard={valueCard}
                      isApplied={Boolean(
                        checkout.valueCardOutcomes.find(
                          o => o.valueCard.id === valueCard.id,
                        ),
                      )}
                    />
                  </li>
                ))}
              </ul>
            )}
          </Accordion.Content>
        </Accordion.Item>
      )}
    </Accordion.Root>
  );
};

const DisabledContainer = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center gap-x-2 bg-gray-200 p-4">
    <Icon icon="CircleExclamationRegular" className="text-signal-warning" />
    <span className="font-medium leading-none">{children}</span>
  </div>
);
