interface Cache {
  dateTime: Map<string, Intl.DateTimeFormat>;
  relativeTime: Map<string, Intl.RelativeTimeFormat>;
}

const CACHE: Cache = {
  dateTime: new Map(),
  relativeTime: new Map(),
};

export const getDateTimeFormatter = (
  locale: MatchiLocale,
  options?: Intl.DateTimeFormatOptions,
) => {
  let key = locale + JSON.stringify(options);
  let formatter = CACHE.dateTime.get(key);

  if (!formatter) {
    formatter = new Intl.DateTimeFormat(locale, options);
    CACHE.dateTime.set(key, formatter);
  }

  return formatter;
};

export const getRelativeTimeFormatter = (
  locale: MatchiLocale,
  options?: Intl.RelativeTimeFormatOptions,
) => {
  let key = locale + JSON.stringify(options);
  let formatter = CACHE.relativeTime.get(key);

  if (!formatter) {
    formatter = new Intl.RelativeTimeFormat(locale, options);
    CACHE.relativeTime.set(key, formatter);
  }

  return formatter;
};
