import { checkoutResponse } from "matchi-api";

export const isOrderCompleted = ({ orderStatus }: checkoutResponse) =>
  (orderStatus as OrderStatus) === "COMPLETED" ||
  (orderStatus as OrderStatus) === "CONFIRMED";

export const isOrderCancelled = ({ orderStatus }: checkoutResponse) =>
  (orderStatus as OrderStatus) === "ANNULLED" ||
  (orderStatus as OrderStatus) === "CANCELLED";

export const isOrderProcessed = (checkout: checkoutResponse) =>
  isOrderCompleted(checkout) || isOrderCancelled(checkout);
