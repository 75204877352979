import { AuthorizedService } from "matchi-api";
import type { PaymentConfig } from "@/services";
import { PaymentService } from "@/services";
import { emitPaymentServiceEvent } from "@/hooks/usePaymentServiceEvents";

/**
 * We need to remove the Adyen redirect params from the URL before creating the pspSession
 * to make sure whe get the same session id when we are redirected back to the checkout.
 */
const ADYEN_REDIRECT_PARAMS = ["redirectResult", "sessionId"];
const getReturnUrl = () => {
  if (typeof window === undefined) return "";

  const url = new URL(window.location.href);
  ADYEN_REDIRECT_PARAMS.forEach(key => url.searchParams.delete(key));

  return url.toString();
};

export const getPaymentServiceQueryKey = (token: string) => [
  "paymentService",
  token,
];

export const getPaymentServiceQuery = (
  token: string,
  paymentConfig?: PaymentConfig,
) => ({
  queryKey: getPaymentServiceQueryKey(token),
  queryFn: async () => {
    const pspSession = await AuthorizedService.getPspSession(
      token,
      getReturnUrl(),
    );

    return PaymentService.init({
      session: {
        id: pspSession.pspSessionId,
        sessionData: pspSession.pspSessionData,
      },
      showPayButton: false,
      onPaymentCompleted: (data, element) => {
        emitPaymentServiceEvent("onPaymentCompleted", { data, element });
      },
      onError: (error, element) => {
        emitPaymentServiceEvent("onError", { error, element });
      },
      ...paymentConfig,
    });
  },
});
