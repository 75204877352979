import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { t, T, useT } from "i18n";
import { getApplyPromoCodeMutation, getCheckoutQuery } from "matchi-api";
import { useForm } from "react-hook-form";
import { useParams, useRevalidator } from "react-router-dom";
import { Button, TextInput } from "ui";
import { z } from "zod";
import { getPaymentOptionsQuery, getPaymentServiceQuery } from "@/queries";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";
import { track } from "@amplitude/analytics-browser";

const validationSchema = z.object({
  promoCode: z
    .string()
    .min(1, { message: t("Promo code must be at least 1 character") }),
});

type ValidationSchema = z.infer<typeof validationSchema>;

interface ApplyPromoCodeProps {
  disabled?: boolean;
}

export const ApplyPromoCode = ({ disabled = false }: ApplyPromoCodeProps) => {
  const queryClient = useQueryClient();
  const { revalidate } = useRevalidator();
  const { token } = useParams();
  const t = useT();
  const { verifyingRedirectResult, paymentFormSubmitting } =
    usePaymentLoadingStore(state => ({
      paymentFormSubmitting: state.paymentFormSubmitting,
      verifyingRedirectResult: state.verifyingRedirectResult,
    }));

  const {
    handleSubmit,
    register,
    setError,
    reset,
    formState: { errors },
  } = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
  });

  const { mutate, isLoading } = useMutation({
    ...getApplyPromoCodeMutation(),
    onSuccess: async (_, variables) => {
      const checkout = await queryClient.fetchQuery(getCheckoutQuery(token!));
      const paymentService = await queryClient.fetchQuery(
        getPaymentServiceQuery(token!),
      );

      const { coupons } = checkout.paymentMethods;
      await queryClient.fetchQuery(
        getPaymentOptionsQuery(coupons, paymentService),
      );

      revalidate();
      reset();

      const { promoCode } = variables;
      track("Applied promo code", { promoCode });
    },
    onError: (_, variables) => {
      // TODO: Set custom messages based on the error code returned
      const message = t("Promo code could not be applied");
      setError("promoCode", { type: "custom", message });

      const { promoCode } = variables;
      track("Failed to apply promo code", { promoCode });
    },
  });

  const isDisabled =
    verifyingRedirectResult || paymentFormSubmitting || disabled || isLoading;

  return (
    <div className="flex flex-col">
      <form
        onSubmit={handleSubmit(({ promoCode }) =>
          mutate({ token: token!, promoCode }),
        )}
      >
        <div className="flex space-x-2">
          <TextInput
            disabled={isDisabled}
            {...register("promoCode")}
            placeholder={t("Enter promo code")}
            error={errors.promoCode?.message}
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck={false}
            block
          />
          <Button loading={isLoading} disabled={isDisabled}>
            <T _str="Apply" />
          </Button>
        </div>
      </form>
    </div>
  );
};
