import { auth } from "matchi-auth";
import { paymentInterval } from "@/utils/paymentInterval";
import { keycloakToMatchiLocale, T, t } from "i18n";
import { formatDateSummary, formatHours } from "format-dates";
import { formatCurrency } from "format-numbers";
import type { article, serviceFee } from "matchi-api";
import { ReactNode } from "react";

interface ArticleListItemProps {
  title: string;
  description?: string | ReactNode;
  price?: string;
  serviceFee?: serviceFee;
}

type ArticleRowProps = {
  article: article;
  timeZone: string;
};

export const ArticleRow = ({ timeZone, article }: ArticleRowProps) => {
  const locale = keycloakToMatchiLocale(auth.getLocale());

  switch (article.type) {
    // TODO: Delete this when the API is updated
    case "SPLIT_FEE":
      return null;

    case "BOOKING":
      return (
        <ArticleListItem
          title={article.metadata.resource ?? "N/A"}
          description={
            <div className="flex items-center space-x-2">
              <span>
                {formatHours({
                  date: new Date(article.metadata.startTime!),
                  locale,
                  timeZone,
                })}
                {" - "}
                {formatHours({
                  date: new Date(article.metadata.endTime!),
                  locale,
                  timeZone,
                })}
              </span>
              <span className="bg-gray-1000 h-1 w-1 rounded-full" />
              <span>
                {formatDateSummary({
                  date: new Date(article.metadata.startTime!),
                  locale,
                  timeZone,
                })}
              </span>
            </div>
          }
          serviceFee={article.serviceFee}
          price={formatCurrency({
            number: parseFloat(article.priceBase?.amount!),
            locale,
            currency: article.price.currency!,
          })}
        />
      );

    case "ACTIVITY":
      return (
        <ArticleListItem
          title={article.metadata.resource ?? "N/A"}
          description={
            <div className="flex items-center space-x-2">
              <span>
                {formatHours({
                  date: new Date(article.metadata.startTime!),
                  locale,
                  timeZone,
                })}
                {" - "}
                {formatHours({
                  date: new Date(article.metadata.endTime!),
                  locale,
                  timeZone,
                })}
              </span>
              <span className="bg-gray-1000 h-1 w-1 rounded-full" />
              <span>
                {formatDateSummary({
                  date: new Date(article.metadata.startTime!),
                  locale,
                  timeZone,
                })}
              </span>
            </div>
          }
          serviceFee={article.serviceFee}
          price={formatCurrency({
            locale,
            number: parseFloat(article.priceBase?.amount!),
            currency: article.price.currency!,
          })}
        />
      );

    case "MEMBERSHIP":
      return (
        <ArticleListItem
          title={article.metadata.resource ?? "N/A"}
          price={`${formatCurrency({
            locale,
            number: parseFloat(article.priceBase?.amount!),
            currency: article.price.currency!,
          })} ${
            article.paymentInterval
              ? `/ ${paymentInterval(article.paymentInterval)}`
              : ""
          }`}
        />
      );

    case "SPLIT":
      return (
        <ArticleListItem
          title={article.metadata.resource ?? "N/A"}
          description={
            <div className="flex items-center space-x-2">
              <span>
                {formatHours({
                  date: new Date(article.metadata.startTime!),
                  locale,
                  timeZone,
                })}
                {" - "}
                {formatHours({
                  date: new Date(article.metadata.endTime!),
                  locale,
                  timeZone,
                })}
              </span>
              <span className="bg-gray-1000 h-1 w-1 rounded-full" />
              <span>
                {formatDateSummary({
                  date: new Date(article.metadata.startTime!),
                  locale,
                  timeZone,
                })}
              </span>
            </div>
          }
          serviceFee={article.serviceFee}
          price={formatCurrency({
            number: parseFloat(article.priceBase?.amount!),
            locale,
            currency: article.price.currency!,
          })}
        />
      );

    default:
      return (
        <ArticleListItem
          title={article.description}
          serviceFee={article.serviceFee}
          price={formatCurrency({
            locale,
            number: parseFloat(article.priceBase?.amount!),
            currency: article.price.currency!,
          })}
        />
      );
  }
};

export const ArticleListItem = ({
  title,
  description,
  price,
  serviceFee,
}: ArticleListItemProps) => {
  const locale = keycloakToMatchiLocale(auth.getLocale());
  const hasServiceFee = serviceFee && parseFloat(serviceFee.amount) > 0;

  return (
    <div className="border-default border-b">
      <div className="flex flex-col items-start justify-between p-4">
        <div className="flex w-full items-center justify-between">
          <h3 className="font-medium">{title}</h3>
          <span className="font-medium">{price}</span>
        </div>
        <div className="flex w-full items-end space-x-2">
          <div className="text-gray-1100 font-medium">{description}</div>
        </div>
      </div>
      {hasServiceFee && (
        <div className="flex items-start justify-between p-4 border-default border-t font-medium">
          <span>
            <T _str="MATCHi service fee" />
          </span>
          <span>
            {formatCurrency({
              locale,
              number: parseFloat(serviceFee.amount),
              currency: serviceFee.currency,
            })}
          </span>
        </div>
      )}
    </div>
  );
};
