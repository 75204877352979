import { createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { Layout } from "./pages/Layout";
import { Payment } from "./pages/Payment";
import { PaymentSuccess } from "./pages/PaymentSuccess";
import { ErrorBoundary } from "@/components";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "pay/:token",
        element: <Payment.Page />,
        loader: Payment.loader,
        action: Payment.action,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "pay/:token/success",
        element: <PaymentSuccess.Page />,
        loader: PaymentSuccess.loader,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);
