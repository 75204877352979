import { t } from "i18n";
import { paymentInterval as paymentIntervalType } from "matchi-api";

export const paymentInterval = ({
  timeUnit,
  interval,
}: paymentIntervalType) => {
  if (timeUnit === "MONTH")
    return interval === 1
      ? t("Monthly")
      : t("Every {interval} months", { interval });

  if (timeUnit === "YEAR")
    return interval === 1
      ? t("Yearly")
      : t("Every {interval} years", { interval });

  return "";
};
