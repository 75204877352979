import type { CheckboxProps as RadixCheckboxProps } from "@radix-ui/react-checkbox";
import * as RadixCheckbox from "@radix-ui/react-checkbox";
import clsx from "clsx";
import { forwardRef, ReactNode } from "react";

type CheckboxProps = {
  label?: ReactNode;
  error?: string;
} & Omit<RadixCheckboxProps, "asChild">;

const styles = {
  base: "w-6 h-6 flex items-center disabled:bg-gray-400 justify-center border border-default hover:border-heavy rounded-md bg-white focus:outline-none focus-visible:ring-2 ring-offset-2 ring-theme-accent transition duration-150 ease-in-out",
  checked: "h-4 w-4 bg-theme-accent rounded",
  indeterminate: "h-2 w-4 bg-gray-600 rounded",
};

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  (
    {
      checked,
      onCheckedChange,
      disabled,
      required,
      name,
      id,
      value,
      label,
      error,
    },
    ref,
  ) => {
    return (
      <div className="flex flex-row space-x-2">
        <RadixCheckbox.Root
          checked={checked}
          onCheckedChange={onCheckedChange}
          disabled={disabled}
          required={required}
          name={name}
          id={id ?? name}
          value={value}
          ref={ref}
          className={clsx(styles.base)}
        >
          <RadixCheckbox.Indicator
            className={
              checked === "indeterminate"
                ? styles.indeterminate
                : styles.checked
            }
          />
        </RadixCheckbox.Root>
        <div>
          {label && (
            <label
              className="text-gray-1200 mb-1 block text-base font-medium"
              htmlFor={id ?? name}
            >
              {label}
            </label>
          )}
          {error && (
            <span className="text-signal-error mt-0.5 block text-sm font-medium">
              {error}
            </span>
          )}
        </div>
      </div>
    );
  },
);

Checkbox.displayName = "Checkbox";
