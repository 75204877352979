import { getPaymentOptionsQuery, getPaymentServiceQuery } from "@/queries";
import { queryClient } from "@/queryClient";
import { useMutation } from "@tanstack/react-query";
import { formatCurrency } from "format-numbers";
import { T, keycloakToMatchiLocale, useT } from "i18n";
import {
  getApplyValueCardMutation,
  getCheckoutQuery,
  getUnapplyValueCardMutation,
  giftCard,
} from "matchi-api";
import { useParams, useRevalidator } from "react-router-dom";
import { Button } from "ui";
import { toast } from "@/components";
import { auth } from "matchi-auth";
import { track } from "@amplitude/analytics-browser";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";

interface ApplyValueCardProps {
  valueCard: giftCard;
  isApplied?: boolean;
}

export const ApplyValueCard = ({
  valueCard,
  isApplied,
}: ApplyValueCardProps) => {
  const t = useT();
  const { revalidate } = useRevalidator();
  const { token } = useParams();
  const locale = keycloakToMatchiLocale(auth.getLocale());
  const { verifyingRedirectResult, paymentFormSubmitting } =
    usePaymentLoadingStore(state => ({
      paymentFormSubmitting: state.paymentFormSubmitting,
      verifyingRedirectResult: state.verifyingRedirectResult,
    }));
  const onMutationSuccess = async () => {
    const checkout = await queryClient.fetchQuery(getCheckoutQuery(token!));
    const paymentService = await queryClient.fetchQuery(
      getPaymentServiceQuery(token!),
    );

    const { coupons } = checkout.paymentMethods;
    await queryClient.fetchQuery(
      getPaymentOptionsQuery(coupons, paymentService),
    );

    revalidate();
  };

  const applyValueCard = useMutation({
    ...getApplyValueCardMutation(),
    onSuccess: (_, variables) => {
      onMutationSuccess();

      const { customerCouponId } = variables;
      track("Applied value card", { valueCardId: customerCouponId });
    },
    onError: (_, variables) => {
      toast({
        title: t("Could not apply value card"),
        description: t("Please try again later."),
      });

      const { customerCouponId } = variables;
      track("Failed to apply value card", { valueCardId: customerCouponId });
    },
  });

  const unapplyValueCard = useMutation({
    ...getUnapplyValueCardMutation(),
    onSuccess: (_, variables) => {
      onMutationSuccess();

      const { valueCardId } = variables;
      track("Applied value card", { valueCardId });
    },
    onError: (_, variables) => {
      toast({
        title: t("Could not unapply value card"),
        description: t("Please try again later."),
      });

      const { valueCardId } = variables;
      track("Failed to unapply value card", { valueCardId });
    },
  });

  return (
    <div className="flex justify-between p-4">
      <div className="flex flex-col">
        <h3 className="font-medium">{valueCard.name}</h3>
        <div className="text-gray-1100 text-sm font-medium">
          {formatCurrency({
            locale,
            number: parseFloat(valueCard.amount),
            currency: valueCard.currency,
          })}
        </div>
      </div>
      <Button
        variant={isApplied ? "secondary" : "primary"}
        disabled={paymentFormSubmitting || verifyingRedirectResult}
        loading={unapplyValueCard.isLoading || applyValueCard.isLoading}
        onClick={() => {
          isApplied
            ? unapplyValueCard.mutate({
                token: token!,
                valueCardId: valueCard.id!,
              })
            : applyValueCard.mutate({
                token: token!,
                customerCouponId: valueCard.id!,
              });
        }}
      >
        {isApplied ? <T _str="Remove" /> : <T _str="Apply" />}
      </Button>
    </div>
  );
};
