const wait = async (ms: number) => new Promise(r => setTimeout(r, ms));

export const poll = async <T>(
  fn: () => Promise<T>,
  fnCondition: (arg: T) => boolean,
  ms: number,
) => {
  let result = await fn();

  while (fnCondition(result)) {
    await wait(ms);
    result = await fn();
  }

  return result;
};
