import { getNumberFormatter } from "./intl";

interface BaseOptions {
  locale: MatchiLocale;
  number: number;
  currency: string;
}

export const formatCurrency = ({ locale, number, currency }: BaseOptions) => {
  const formatter = getNumberFormatter(locale, {
    style: "currency",
    currency,
  });

  return formatter.format(number);
};
