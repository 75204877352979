import { T } from "i18n";
import { useRouteError } from "react-router-dom";
import { Button, Link } from "ui";
import { auth } from "matchi-auth";

interface PaymentErrorProps {
  errorMessage?: string;
}

export const ErrorBoundary = ({ errorMessage }: PaymentErrorProps) => {
  const error = useRouteError() as Error;
  const idTokenParsed = auth.getIdTokenParsed();
  const url = window?.location?.href ? new URL(window.location.href) : null;
  const message = errorMessage ?? error?.message;

  // If checkout data could not be fetched, it's likely due to the current logged in user being not the owner
  // of the order, so we provide a more helpful message and a logout button for these cases.
  if (message === "Could not fetch checkout data") {
    return (
      <>
        <h1 className="mb-2 text-lg font-medium">
          <T _str="We were unable to process your order" />
        </h1>
        <p className="mb-3">
          <T
            _str="Please make sure that you are signed in to the correct user account. If the problem persists, please contact {email}."
            email={
              <span className="inline">
                <Link href="mailto:support@matchi.com">support@matchi.com</Link>
              </span>
            }
          />
        </p>
        <div className="mb-3 flex flex-col rounded-lg bg-gray-300 px-4 py-3 text-xs">
          {idTokenParsed && <code>user: {idTokenParsed.email}</code>}
          {url && <code>path: {url.pathname}</code>}
        </div>
        <Button onClick={auth.logout} block>
          <T _str="Logout" />
        </Button>
      </>
    );
  }

  return (
    <>
      <h1 className="mb-2 text-lg font-medium">
        <T _str="Something went wrong" />
      </h1>

      <div className="border-default mb-4 mt-4 rounded-xl border p-4">
        <p className="text-gray-1100 font-medium">
          <T
            _str="Do you have questions or need help? Reach out to our support team at {email}."
            email={
              <span className="inline">
                <Link href="mailto:support@matchi.com">support@matchi.com</Link>
              </span>
            }
          />
        </p>
      </div>
      <div className="mb-3 flex flex-col rounded-lg bg-gray-300 px-4 py-3 text-xs">
        {idTokenParsed && <code>user: {idTokenParsed.email}</code>}
        {url && <code>path: {url.pathname}</code>}
        {message && <code>error: {message}</code>}
      </div>
    </>
  );
};
