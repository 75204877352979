import { AnimatePresence, motion } from "framer-motion";
import type { Renderable, ToastOptions } from "react-hot-toast";
import { toast as t, useToaster } from "react-hot-toast/headless";
import { IconButton } from "ui";

interface CustomToastOptions {
  title: string;
  description?: string;
  options?: ToastOptions;
}

export const toast = ({
  title,
  description,
  options = { duration: 3500 },
}: CustomToastOptions) => {
  const toastId = t.custom(
    <div className="border-light flex w-full space-x-4 border-b bg-white px-4 py-4 shadow-sm md:px-6">
      <div className="flex flex-1 flex-col">
        <span className="text-signal-error font-medium">{title}</span>
        <span className="text-gray-1200 font-medium leading-normal">
          {description}
        </span>
      </div>
      <IconButton icon="XmarkSolid" onClick={() => t.dismiss(toastId)} />
    </div>,
    options,
  );
};

export const Toaster = () => {
  const { toasts } = useToaster({
    duration: Infinity,
  });

  return (
    <AnimatePresence mode="popLayout">
      {toasts
        .filter(toast => toast.visible)
        .map(({ id, message }) => {
          return (
            <motion.div
              layoutId={id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key={id}
            >
              {message as Renderable}
            </motion.div>
          );
        })}
    </AnimatePresence>
  );
};
