import { AuthorizedService } from "@matchi/api";

interface ApplyPromoCodeVariables {
  token: string;
  promoCode: string;
}

export const getApplyPromoCodeMutationKey = (
  token: string,
  promoCode: string,
) => ["applyPromoCode", token, promoCode];

export const getApplyPromoCodeMutation = () => ({
  mutationFn: ({ token, promoCode }: ApplyPromoCodeVariables) =>
    AuthorizedService.applyPromoCode(token, { promoCode }),
});
