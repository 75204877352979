import { Outlet, useNavigation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Logo } from "ui";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";
import { Toaster } from "@/components";

export const Layout = () => {
  const state = usePaymentLoadingStore(state => ({
    showProgressBar:
      state.isLoading ||
      state.paymentFormSubmitting ||
      state.verifyingRedirectResult,
    fadeContent: state.isLoading || state.verifyingRedirectResult,
  }));

  return (
    <>
      <div className="fixed top-0 z-50 w-full">
        <motion.header
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.25 }}
          className="border-light  w-full border-b bg-white antialiased"
        >
          <div className="mx-auto flex max-w-5xl items-center justify-center px-4 py-2">
            <div className="flex items-center space-x-2 py-2">
              <Logo type="default" className="h-5" />
            </div>
          </div>
          {/* Progress bar */}
          <AnimatePresence>
            {state.showProgressBar && (
              <motion.div
                className="absolute inset-x-0 bottom-0"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                {/* TODO: Add styles for this */}
                <motion.div
                  className="h-[2px] w-full bg-gradient-to-r from-transparent via-green-600 to-transparent"
                  initial={{ x: "-100%" }}
                  animate={{ x: "100%" }}
                  transition={{ repeat: Infinity, duration: 1 }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </motion.header>
        <Toaster />
      </div>
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: state.fadeContent ? 0.5 : 1 }}
        className="mx-auto max-w-lg px-4 pb-16 pt-24 antialiased"
      >
        <Outlet />
      </motion.main>
    </>
  );
};
