import { Suspense, useState } from "react";
import { Button, Icon, Image } from "ui";
import { ArticleRow, Dialog, PriceDetailRow } from "@/components";
import { usePaymentLoadingStore } from "@/paymentLoadingStore";
import { queryClient } from "@/queryClient";
import { getQueryParam } from "@/utils";
import { formatCurrency } from "format-numbers";
import {
  T,
  init,
  keycloakToMatchiLocale,
  keycloakToTransifexLocale,
} from "i18n";
import {
  checkoutResponse,
  getCheckoutQuery,
  getUserInfoQuery,
  userInfo,
} from "matchi-api";
import {
  Await,
  LoaderFunctionArgs,
  defer,
  useLoaderData,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import { auth } from "matchi-auth";
import { getTokenOrLogin } from "@/utils/auth";
import { setUserId, track } from "@amplitude/analytics-browser";
import { setUser } from "@sentry/react";
import to from "await-to-js";

interface LoaderData {
  checkout: checkoutResponse;
  user: userInfo;
  onSuccessUrl: string;
}

const Page = () => {
  // TODO: These values should be inferred from loader data, but they are not.
  // @see https://github.com/remix-run/react-router/discussions/9792
  const { data } = useLoaderData() as Awaited<ReturnType<typeof loader>>;
  const [priceDetailsOpen, setPriceDetailsOpen] = useState(false);

  return (
    <Suspense>
      <Await resolve={data}>
        {/* Data should be correctly inferred from useLoaderData, and we would not need this type */}
        {({ checkout, user, onSuccessUrl }: LoaderData) => {
          const locale = keycloakToMatchiLocale(auth.getLocale());
          return (
            <>
              <div className="mb-6 space-y-2">
                <h1 className="text-lg font-medium">
                  <T _str="Thank you!" />
                </h1>
                <p className="text-gray-1200 leading-normal">
                  <T
                    _str="Your order has been successfully completed. An email with additional details have be sent to {email}."
                    email={
                      <span className="inline font-medium text-black">
                        {user.email}
                      </span>
                    }
                  />
                </p>
              </div>
              <div className="border-default mb-4 overflow-hidden rounded-xl border">
                <header className="border-default flex items-center space-x-3 border-b bg-gray-200 px-4 py-3">
                  <div className="h-6 w-6 overflow-hidden rounded-md">
                    <Image
                      className="w-full rounded-md"
                      src={checkout.facilityImageUrl}
                      alt="logo"
                    />
                  </div>
                  <h2 className="font-medium leading-none">
                    {checkout.facilityName}
                  </h2>
                </header>
                <ul>
                  {checkout.articles.map((article, index) => (
                    <li key={index}>
                      <ArticleRow
                        timeZone={checkout.facilityTimeZone!}
                        article={article}
                      />
                    </li>
                  ))}
                </ul>
                {checkout.promoCodeOutcomes.length > 0 &&
                  checkout.promoCodeOutcomes.map(promoCode => (
                    <SummaryOfferRow
                      key={promoCode.promoCode}
                      title={promoCode.promoCode}
                      discountAmount={formatCurrency({
                        locale,
                        number: parseFloat(promoCode.amount),
                        currency: checkout.price!.currency!,
                      })}
                    />
                  ))}
                {checkout.valueCardOutcomes.length > 0 &&
                  checkout.valueCardOutcomes.map(({ valueCard, amount }) => (
                    <SummaryOfferRow
                      key={valueCard.id}
                      title={valueCard.name}
                      discountAmount={formatCurrency({
                        locale,
                        number: amount,
                        currency: valueCard.currency,
                      })}
                    />
                  ))}
                <div className="flex justify-between p-4">
                  <span className="text-lg font-medium">
                    <T _str="Total" />
                  </span>
                  <span className="text-theme-accent text-lg font-medium">
                    {formatCurrency({
                      locale,
                      number: parseFloat(checkout.price?.amount!),
                      currency: checkout.price?.currency!,
                    })}
                  </span>
                </div>
                <div className="flex flex-1 bg-gray-200 p-4 border-t border-default">
                  <button
                    onClick={() => setPriceDetailsOpen(true)}
                    className="flex flex-1 justify-between items-center font-medium"
                  >
                    <span>
                      <T _str="Price details" />
                    </span>
                    <Icon icon="ChevronRightSolid" className="w-4" />
                  </button>
                  <Dialog
                    open={priceDetailsOpen}
                    onClose={() => setPriceDetailsOpen(false)}
                    title={<T _str="Price details" />}
                  >
                    <ul>
                      {checkout.articles.map((article, index) => (
                        <li key={index}>
                          <PriceDetailRow article={article} />
                        </li>
                      ))}
                    </ul>
                  </Dialog>
                </div>
              </div>
              {onSuccessUrl && (
                <div className="mb-4">
                  <Button
                    block
                    onClick={() => (window.location.href = onSuccessUrl)}
                  >
                    <T _str="Go back" />
                  </Button>
                </div>
              )}
            </>
          );
        }}
      </Await>
    </Suspense>
  );
};

export const loader = async ({ params: { token } }: LoaderFunctionArgs) => {
  // We need to return a promise from the loader or Suspense will not wait for the data to be fetched.
  // @see https://reactrouter.com/en/main/guides/deferred#using-defer
  const fetchCheckoutData = async () => {
    if (!token) throw new Error("No token found");

    usePaymentLoadingStore.setState({
      isLoading: true,
      paymentFormSubmitting: false,
      verifyingRedirectResult: false,
    });

    // Get berarer token from keycloak
    const bearer = await getTokenOrLogin();
    const { locale: userLocale, sub: keycloakId } =
      jwtDecode<KeycloakJWTPayload>(bearer);

    // Identify users in Amplitude and Sentry
    setUserId(keycloakId);
    setUser({ id: keycloakId });

    // Initialize Transifex
    const locale = keycloakToTransifexLocale(userLocale);
    await init({ token: import.meta.env.VITE_TRANSIFEX_TOKEN, locale });

    let err, user, checkout;

    [err, user] = await to(queryClient.ensureQueryData(getUserInfoQuery()));

    if (err || !user) throw new Error("Could not fetch user data");

    [err, checkout] = await to(
      queryClient.ensureQueryData(getCheckoutQuery(token)),
    );

    if (err || !checkout) throw new Error("Could not fetch checkout data");

    const onSuccessUrl = getQueryParam("onSuccessUrl");

    return { user, checkout, onSuccessUrl };
  };

  const data = fetchCheckoutData()
    .catch(error => {
      track(error.message);
      throw error;
    })
    .finally(() => {
      usePaymentLoadingStore.setState({
        isLoading: false,
        paymentFormSubmitting: false,
        verifyingRedirectResult: false,
      });
    });

  return defer({ data });
};

const SummaryOfferRow = ({
  title,
  discountAmount,
}: {
  title: string;
  discountAmount: string | number;
}) => {
  return (
    <div className="flex justify-between border-b border-default p-4">
      <span className="font-medium">{title}</span>
      <span className="font-medium">-{discountAmount}</span>
    </div>
  );
};

export const PaymentSuccess = { Page, loader };
