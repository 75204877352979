import { getQueryParam, removeQueryParam } from "@/utils";
import { OpenAPI } from "matchi-api";
import jwtDecode from "jwt-decode";
import { auth } from "matchi-auth";

const TOKEN_STORAGE_KEY = "matchi_checkout_token";

const updateOpenApiConfig = (bearerToken: string) => {
  OpenAPI.BASE = import.meta.env.VITE_MATCHI_API_URL;
  OpenAPI.HEADERS = { "x-api-key": import.meta.env.VITE_MATCHI_API_KEY };
  OpenAPI.TOKEN = bearerToken;
};

const isTokenValid = (token: string) => {
  try {
    const { exp } = jwtDecode<KeycloakJWTPayload>(token);
    return Date.now() < exp * 1000;
  } catch (error) {
    return false;
  }
};

export const getTokenOrLogin = async () => {
  if (OpenAPI.TOKEN) return OpenAPI.TOKEN as string;

  const tokenParam = getQueryParam("token");
  if (tokenParam) {
    removeQueryParam("token");

    if (isTokenValid(tokenParam)) {
      localStorage.setItem(TOKEN_STORAGE_KEY, tokenParam);
      updateOpenApiConfig(tokenParam);
      return tokenParam;
    }
  }

  const storedToken = localStorage.getItem(TOKEN_STORAGE_KEY);
  if (storedToken) {
    if (isTokenValid(storedToken)) {
      updateOpenApiConfig(storedToken);
      return storedToken;
    }

    localStorage.removeItem(TOKEN_STORAGE_KEY);
  }

  const authenticated = await auth.init({
    config: {
      url: import.meta.env.VITE_KEYCLOAK_URL,
      realm: import.meta.env.VITE_KEYCLOAK_REALM,
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENT_ID,
    },
    options: {
      silentCheckSsoRedirectUri:
        window.location.origin + "/silent-check-sso.html",
    },
  });

  if (authenticated) {
    const token = auth.getToken()!;
    updateOpenApiConfig(token);
    return token;
  }

  await auth.login();

  return "";
};

export const getLocale = () => {
  try {
    const keycloakLocale = auth.getLocale();
    if (keycloakLocale) return keycloakLocale;

    if (OpenAPI.TOKEN) {
      const { locale } = jwtDecode<KeycloakJWTPayload>(OpenAPI.TOKEN as string);
      return locale ?? "en";
    }
    return "en";
  } catch (error) {
    return "en";
  }
};
