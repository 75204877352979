import { auth } from "matchi-auth";
import { paymentInterval } from "@/utils/paymentInterval";
import { keycloakToMatchiLocale, T, t } from "i18n";
import { formatCurrency } from "format-numbers";
import type { article, serviceFee } from "matchi-api";

type PriceDetailRowProps = {
  article: article;
};

export const PriceDetailRow = ({ article }: PriceDetailRowProps) => {
  const locale = keycloakToMatchiLocale(auth.getLocale());

  switch (article.type) {
    // TODO: Delete this when the API is updated
    case "SPLIT_FEE":
      return null;

    case "BOOKING":
      return (
        <PriceDetailListItem
          title={article.metadata.resource ?? "N/A"}
          vatPercentage={article.price?.vatValue}
          vat={formatCurrency({
            locale,
            number: parseFloat(article.price?.vat!),
            currency: article.price.currency!,
          })}
          price={formatCurrency({
            number: parseFloat(article.price?.amount!),
            locale,
            currency: article.price.currency!,
          })}
          serviceFee={article.serviceFee}
        />
      );

    case "ACTIVITY":
      return (
        <PriceDetailListItem
          title={article.metadata.resource ?? "N/A"}
          vatPercentage={article.price?.vatValue}
          vat={formatCurrency({
            locale,
            number: parseFloat(article.price?.vat!),
            currency: article.price.currency!,
          })}
          serviceFee={article.serviceFee}
          price={formatCurrency({
            locale,
            number: parseFloat(article.price?.amount!),
            currency: article.price.currency!,
          })}
        />
      );

    case "MEMBERSHIP":
      return (
        <PriceDetailListItem
          title={article.metadata.resource ?? "N/A"}
          vatPercentage={article.price?.vatValue}
          vat={formatCurrency({
            locale,
            number: parseFloat(article.price?.vat!),
            currency: article.price.currency!,
          })}
          serviceFee={article.serviceFee}
          price={`${formatCurrency({
            locale,
            number: parseFloat(article.price?.amount!),
            currency: article.price.currency!,
          })} ${
            article.paymentInterval
              ? `/ ${paymentInterval(article.paymentInterval)}`
              : ""
          }`}
        />
      );

    case "SPLIT":
      return (
        <PriceDetailListItem
          title={article.metadata.resource ?? "N/A"}
          vatPercentage={article.price?.vatValue}
          serviceFee={article.serviceFee}
          price={formatCurrency({
            number: parseFloat(article.price?.amount!),
            locale,
            currency: article.price.currency!,
          })}
          vat={formatCurrency({
            locale,
            number: parseFloat(article.price?.vat!),
            currency: article.price.currency!,
          })}
        />
      );

    default:
      return (
        <PriceDetailListItem
          title={article.description}
          vatPercentage={article.price?.vatValue}
          vat={formatCurrency({
            locale,
            number: parseFloat(article.price?.vat!),
            currency: article.price.currency!,
          })}
          serviceFee={article.serviceFee}
          price={formatCurrency({
            locale,
            number: parseFloat(article.priceBase?.amount!),
            currency: article.price.currency!,
          })}
        />
      );
  }
};

interface PriceDetailListItemProps {
  title: string;
  vatPercentage?: number | string;
  vat?: number | string;
  price?: string;
  serviceFee?: serviceFee;
}

const PriceDetailListItem = ({
  title,
  price,
  vatPercentage,
  vat,
  serviceFee,
}: PriceDetailListItemProps) => {
  const locale = keycloakToMatchiLocale(auth.getLocale());
  const hasServiceFee = serviceFee && parseFloat(serviceFee.amount) > 0;

  return (
    <div>
      <div className="flex flex-col items-start justify-between p-4">
        <div className="flex w-full items-center justify-between">
          <h3 className="font-medium">{title}</h3>
          <span className="font-medium">{price}</span>
        </div>
        <div className="flex w-full items-center justify-between text-gray-1100 font-medium">
          <span>
            <T _str="Included VAT ({vat}%)" vat={vatPercentage}></T>
          </span>
          <span>{vat}</span>
        </div>
      </div>
      {hasServiceFee && (
        <div className="flex flex-col items-start justify-between px-4 pb-4">
          <div className="flex w-full items-center justify-between pt-4 border-default border-t">
            <h3 className="font-medium">
              <T _str="MATCHi service fee" />
            </h3>
            <span className="font-medium">
              {formatCurrency({
                locale,
                number: parseFloat(serviceFee.amount),
                currency: serviceFee.currency,
              })}
            </span>
          </div>
          <div className="flex w-full items-center justify-between text-gray-1100 font-medium mb-2">
            <span>
              <T _str="Included VAT ({vat}%)" vat={serviceFee.vatValue}></T>
            </span>
            <span>
              {formatCurrency({
                locale,
                number: parseFloat(serviceFee.vat),
                currency: serviceFee.currency,
              })}
            </span>
          </div>
          <div className="text-gray-1100">
            <T _str="A service fee is applied to support continuous platform improvements and ensure secure bookings for all users. The fee is non-refundable." />
          </div>
        </div>
      )}
    </div>
  );
};
