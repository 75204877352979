/// <reference path="../../types/global.d.ts" />

import * as txNative from "@transifex/native";
import * as txReact from "@transifex/react";

type MatchiTransifexLocales = {
  [key in MatchiLocale]: TransifexLocale;
};

const MATCHI_TRANSIFEX_LOCALE_MAP: MatchiTransifexLocales = {
  "en-GB": "en",
  "en-US": "en",
  "da-DK": "da",
  "de-DE": "de",
  "es-ES": "es",
  "fi-FI": "fi",
  "fr-FR": "fr",
  "hr-HR": "hr",
  "nb-NO": "no",
  "sv-SE": "sv",
  "nl-NL": "nl",
};

type KeycloakTransifexLocaleMap = {
  [key in KeycloakLocale]: TransifexLocale;
};

const KEYCLOAK_TRANSIFEX_LOCALE_MAP: KeycloakTransifexLocaleMap = {
  en: "en",
  sv: "sv",
  da: "da",
  de: "de",
  es: "es",
  fi: "fi",
  fr: "fr",
  no: "no",
  nl: "nl",
};

type KeycloakMatchiLocaleMap = {
  [key in KeycloakLocale]: MatchiLocale;
};

const KEYCLOAK_MATCHI_LOCALE_MAP: KeycloakMatchiLocaleMap = {
  en: "en-GB",
  da: "da-DK",
  de: "de-DE",
  es: "es-ES",
  fi: "fi-FI",
  fr: "fr-FR",
  no: "nb-NO",
  sv: "sv-SE",
  nl: "nl-NL",
};

const DEFAULT_TRANSIFEX_LOCALE = MATCHI_TRANSIFEX_LOCALE_MAP["en-GB"];
const DEFAULT_MATCHI_LOCALE = KEYCLOAK_MATCHI_LOCALE_MAP["en"];

export const matchiToTransifexLocale = (
  locale: MatchiLocale,
): TransifexLocale =>
  MATCHI_TRANSIFEX_LOCALE_MAP[locale] ?? DEFAULT_TRANSIFEX_LOCALE;

export const keycloakToTransifexLocale = (
  locale?: KeycloakLocale,
): TransifexLocale => {
  if (!locale) return DEFAULT_TRANSIFEX_LOCALE;
  return KEYCLOAK_TRANSIFEX_LOCALE_MAP[locale] ?? DEFAULT_TRANSIFEX_LOCALE;
};

export const keycloakToMatchiLocale = (
  locale?: KeycloakLocale,
): MatchiLocale => {
  if (!locale) return DEFAULT_MATCHI_LOCALE;
  return KEYCLOAK_MATCHI_LOCALE_MAP[locale] ?? DEFAULT_MATCHI_LOCALE;
};

interface InitOptions {
  token: string;
  locale: TransifexLocale;
}

export const init = async ({ token, locale }: InitOptions) => {
  try {
    txNative.tx.init({ token });
    await txNative.tx.setCurrentLocale(locale);
    return true;
  } catch (error: any) {
    throw new Error(error);
  }
};

// Re-export Transifex Native methods
export const t = txNative.t;

// Re-export Transifex React methods
export const T = txReact.T;
export const useT = txReact.useT;
