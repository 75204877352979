import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { useMeasure } from "react-use";
import { RadioGroup } from "ui";
import { MixedPaymentOption, PaymentServiceOption } from "@/queries";

interface PaymentOptionProps {
  option: MixedPaymentOption;
  selected: boolean;
}

export const PaymentOption = ({ option, selected }: PaymentOptionProps) => {
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  const renderOption = () => {
    if (option.type === "COUPON") return null;

    if (option.type === "PAYMENT_SERVICE") {
      return (
        <PaymentServiceComponent option={option as PaymentServiceOption} />
      );
    }
  };

  return (
    <div
      className={`border-default relative ${
        selected ? "z-20" : "z-10"
      } border-b last-of-type:border-b-0`}
    >
      <div className="flex items-center space-x-2 p-4">
        <RadioGroup.Item
          value={option.id}
          id={option.id}
          disabled={option.disabled}
        >
          <RadioGroup.Indicator />
        </RadioGroup.Item>
        <label
          className="flex flex-1 flex-row items-center justify-between"
          htmlFor={option.id}
        >
          <span className="flex flex-col">
            <span className="font-medium leading-tight">{option.title}</span>
            {option.description && (
              <span className="text-gray-1100 text-sm font-medium">
                {option.description}
              </span>
            )}
          </span>
          <span>{option.icon}</span>
        </label>
      </div>
      <AnimatePresence>
        {selected && (
          <motion.div
            key={option.id}
            initial={{ opacity: 0, height: 0, overflow: "hidden" }}
            animate={{ opacity: 1, height, overflow: "visible" }}
            exit={{ opacity: 0, height: 0, overflow: "hidden" }}
            transition={{ duration: 0.25 }}
          >
            <div ref={ref}>{renderOption()}</div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const PaymentServiceComponent = ({
  option,
}: {
  option: PaymentServiceOption;
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if (option.component && ref.current) {
      option.component.mount(ref.current);
    }

    return () => {
      option.component.unmount();
    };
  }, [option.component, ref]);

  return <div className="px-4 pb-4 empty:pb-0" ref={ref} />;
};
