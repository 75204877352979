import Checkout from "@adyen/adyen-web";
import type Core from "@adyen/adyen-web/dist/types/core";
import type { CoreOptions } from "@adyen/adyen-web/dist/types/core/types";

export type PaymentConfig = Partial<CoreOptions>;

const defaultConfig: CoreOptions = {
  clientKey: import.meta.env.VITE_ADYEN_CHECKOUT_CLIENT_KEY,
  environment: import.meta.env.VITE_ADYEN_CHECKOUT_ENV,
};

const init = async (config?: PaymentConfig): Promise<Core> => {
  const checkout = await Checkout({ ...defaultConfig, ...config });
  return checkout;
};

export const PaymentService = {
  init,
};
