import { AuthorizedService } from "@matchi/api";

interface UnapplyPromoCodeVariables {
  token: string;
}

export const getUnapplyPromoCodeMutationKey = (token: string) => [
  "unapplyPromoCode",
  token,
];

export const getUnapplyPromoCodeMutation = () => ({
  mutationFn: ({ token }: UnapplyPromoCodeVariables) =>
    AuthorizedService.unapplyPromocode(token),
});
