import clsx from "clsx";
import { forwardRef, InputHTMLAttributes } from "react";

export type InputProps = {
  label?: string;
  description?: string;
  error?: string;
  block?: boolean;
  size?: "sm" | "md" | "lg";
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

const styles = {
  base: "antialiased inline-flex rounded-lg bg-white disabled:bg-gray-400 font-medium text-gray-1200 placeholder:text-gray-1100 border border-default hover:border-heavy whitespace-nowrap outline-none focus:ring-2 ring-theme-accent ring-offset-1 transition duration-150 ease-in-out",
  size: {
    sm: "h-8 px-3",
    md: "h-10 px-4",
    lg: "h-12 px-4",
  },
  block: "flex w-full",
  error: "ring-2 ring-signal-error",
};

export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      description,
      error,
      block,
      size = "md",
      type = "text",
      autoComplete,
      autoFocus,
      disabled,
      placeholder,
      name,
      id,
      onChange,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={clsx([block && styles.block, "flex-col"])}>
        {label && (
          <label
            className="text-gray-1200 mb-1 block text-sm font-medium"
            htmlFor={id ?? name}
          >
            {label}
          </label>
        )}
        <input
          type={type}
          id={id ?? name}
          name={name}
          disabled={disabled}
          autoComplete={autoComplete}
          placeholder={placeholder}
          autoFocus={autoFocus}
          className={clsx([
            styles.base,
            styles.size[size],
            error && styles.error,
            block && styles.block,
          ])}
          onChange={onChange}
          ref={ref}
          {...props}
        />
        {error && (
          <span className="text-signal-error mt-0.5 block text-sm font-medium">
            {error}
          </span>
        )}
        {!error && description && (
          <span className="text-gray-1000 mt-0.5 block text-sm font-medium">
            {description}
          </span>
        )}
      </div>
    );
  },
);

TextInput.displayName = "TextInput";
