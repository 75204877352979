import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import type { ElementType, ForwardedRef } from "react";
import { forwardRef } from "react";
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";
import { Icon, IconProps } from "../Icon";
import { Loading } from "../Loading";

const defaultElement = "button";

export type ButtonOwnProps = {
  loading?: boolean;
  size?: "sm" | "md" | "lg";
  variant?: "primary" | "secondary";
  block?: boolean;
  leadingIcon?: IconProps["icon"];
  trailingIcon?: IconProps["icon"];
} & Pick<
  JSX.IntrinsicElements["button"],
  | "onClick"
  | "onMouseEnter"
  | "onMouseLeave"
  | "children"
  | "disabled"
  | "type"
  | "tabIndex"
>;

export type ButtonProps<T extends React.ElementType = typeof defaultElement> =
  PolymorphicPropsWithRef<ButtonOwnProps, T>;

const containerStyles = {
  base: "antialiased relative rounded-lg inline-flex items-center justify-center font-medium whitespace-nowrap outline-none focus:ring-2 ring-offset-1 ring-theme-accent transition duration-150 ease-in-out",
  size: {
    sm: "h-8 px-3",
    md: "h-10 px-4",
    lg: "h-12 px-4",
  },
  variant: {
    primary:
      "bg-theme-button-primary-background-default border border-theme-button-primary-background-default hover:bg-theme-button-primary-background-hover text-theme-button-primary-text-default shadow-button",
    secondary:
      "bg-gray-400 border border-default hover:bg-gray-500 text-black shadow-button",
  },
  block: "flex w-full",
  disabled: "opacity-50 cursor-not-allowed",
};

const innerStyles = {
  base: "inline-flex space-x-2 items-center",
};

const loadingStyles = {
  variant: {
    primary: "text-theme-button-primary-text-default",
    secondary: "text-black",
  },
};

const iconStyles = {
  variant: {
    primary: "text-theme-button-primary-text-default",
    secondary: "text-black",
  },
  size: {
    sm: "text-sm",
    md: "text-sm",
    lg: "text-base",
  },
};

const MotionLoading = motion(Loading);

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonOwnProps,
  typeof defaultElement
> = forwardRef(function Heading<T extends ElementType = typeof defaultElement>(
  {
    size = "md",
    variant = "primary",
    leadingIcon,
    trailingIcon,
    block,
    children,
    loading,
    disabled,
    as,
    className,
    ...rest
  }: PolymorphicPropsWithoutRef<ButtonOwnProps, T>,
  ref: ForwardedRef<Element>,
) {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      ref={ref}
      className={clsx([
        containerStyles.base,
        containerStyles.size[size],
        containerStyles.variant[variant],
        disabled && containerStyles.disabled,
        block && containerStyles.block,
        className,
      ])}
      disabled={loading || disabled}
      {...rest}
    >
      <AnimatePresence mode="wait">
        {loading && (
          <MotionLoading
            initial={{ opacity: 0, position: "absolute" }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={clsx([loadingStyles.variant[variant]])}
            size="sm"
          />
        )}
      </AnimatePresence>
      <motion.div
        className={clsx([innerStyles.base])}
        animate={{ opacity: loading ? 0 : 1 }}
      >
        {leadingIcon && (
          <Icon
            className={clsx([
              iconStyles.variant[variant],
              iconStyles.size[size],
            ])}
            icon={leadingIcon}
          />
        )}
        <div>{children}</div>
        {trailingIcon && (
          <Icon
            className={clsx([
              iconStyles.variant[variant],
              iconStyles.size[size],
            ])}
            icon={trailingIcon}
          />
        )}
      </motion.div>
    </Element>
  );
});

Button.displayName = "Button";
