import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import type { ElementType, ForwardedRef } from "react";
import { forwardRef } from "react";
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithRef,
  PolymorphicPropsWithoutRef,
} from "react-polymorphic-types";
import { Icon, IconProps } from "../Icon/Icon";
import { Loading } from "../Loading";

const defaultElement = "button";

export type IconButtonOwnProps = {
  loading?: boolean;
  size?: "sm" | "md" | "lg";
} & Omit<IconProps, "className"> &
  Pick<
    JSX.IntrinsicElements["button"],
    | "onClick"
    | "onMouseEnter"
    | "onMouseLeave"
    | "children"
    | "disabled"
    | "type"
    | "tabIndex"
  >;

export type IconButtonProps<
  T extends React.ElementType = typeof defaultElement,
> = PolymorphicPropsWithRef<IconButtonOwnProps, T>;

const styles = {
  base: "antialiased text-black rounded-lg inline-flex items-center justify-center outline-none focus:ring-2 ring-offset-1 ring-theme-accent hover:bg-gray-500 transition duration-150 ease-in-out",
  size: {
    sm: "h-8 w-8",
    md: "h-10 w-10",
    lg: "h-12 w-12",
  },
  icon: {
    sm: "text-base text-black",
    md: "text-base text-black",
    lg: "text-xl text-black",
  },
  disabled: "opacity-50 cursor-not-allowed",
};

const MotionLoading = motion(Loading);
const MotionIcon = motion(Icon);

export const IconButton: PolymorphicForwardRefExoticComponent<
  IconButtonOwnProps,
  typeof defaultElement
> = forwardRef(function Heading<T extends ElementType = typeof defaultElement>(
  {
    size = "md",
    icon,
    loading,
    disabled,
    className,
    as,
    ...rest
  }: PolymorphicPropsWithoutRef<IconButtonOwnProps, T>,
  ref: ForwardedRef<Element>,
) {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      ref={ref}
      className={clsx([
        styles.base,
        styles.size[size],
        disabled && styles.disabled,
      ])}
      disabled={loading || disabled}
      {...rest}
    >
      <AnimatePresence mode="wait">
        {loading && (
          <MotionLoading
            initial={{ opacity: 0, position: "absolute" }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            size={size === "lg" ? "md" : size}
          />
        )}
      </AnimatePresence>
      <MotionIcon
        animate={{ opacity: loading ? 0 : 1 }}
        className={clsx([styles.icon[size]])}
        icon={icon}
      />
    </Element>
  );
});

IconButton.displayName = "IconButton";
