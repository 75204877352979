import { AuthorizedService } from "@matchi/api";

interface UnapplyValueCardVariables {
  token: string;
  valueCardId: number;
}

export const getUnapplyValueCardMutationKey = (
  token: string,
  valueCardId: string | number,
) => ["unapplyValueCard", token, valueCardId];

export const getUnapplyValueCardMutation = () => ({
  mutationFn: ({ token, valueCardId }: UnapplyValueCardVariables) =>
    AuthorizedService.unapplyValueCard(token, valueCardId),
});
