import Core from "@adyen/adyen-web/dist/types/core";
import { PaymentMethod } from "@adyen/adyen-web/dist/types/types";
import RedirectElement from "@adyen/adyen-web/dist/types/components/Redirect/Redirect";
import { coupon } from "matchi-api";
import { t } from "i18n";
import { ReactNode } from "react";
import { PaymentMethodIcon } from "@/components";
import to from "await-to-js";

interface BasePaymentOption {
  id: string;
  type: PaymentType;
  title: string;
  description?: string;
  icon?: ReactNode;
  disabled?: boolean;
}

export interface InternalPaymentOption extends BasePaymentOption {
  method: coupon;
}

export interface PaymentServiceOption extends BasePaymentOption {
  method: PaymentMethod;
  component: RedirectElement;
}

const createPaymentServiceOptions = async (
  paymentService?: Core,
): Promise<PaymentServiceOption[]> => {
  if (!paymentService) return [];

  const methods = [
    ...paymentService.paymentMethodsResponse.storedPaymentMethods,
    ...paymentService.paymentMethodsResponse.paymentMethods,
  ];

  const options: PaymentServiceOption[] = [];

  for (const [index, method] of methods.entries()) {
    const component = paymentService.create(method.type, method);

    // Components are typed incorrectly by Adyen, some methods like Apple Pay and Google Pay do have isAvailable method on them.
    // @see https://docs.adyen.com/payment-methods/apple-pay/web-component/#step-3-mount-the-component
    // @ts-ignore
    if (component.isAvailable) {
      // @ts-ignore
      const [err, isAvailable] = await to(component.isAvailable());
      if (err || !isAvailable) continue;
    }

    options.push({
      id: `PAYMENT_SERVICE-${index}`,
      type: "PAYMENT_SERVICE" as const,
      title: method.name,
      icon: <PaymentMethodIcon type="PAYMENT_SERVICE" method={method} />,
      method,
      component,
    });
  }

  return options;
};

export const createCouponOptions = (
  coupons: coupon[] = [],
): InternalPaymentOption[] => {
  const options = coupons.map((method, index) => ({
    id: `COUPON-${method.method}-${index}`,
    type: "COUPON" as const,
    title: method.name,
    description: method.unlimited
      ? t("Unlimited")
      : t("{punches} punches remaining ({duration} min)", {
          punches: method.punches,
          duration: method.maxDuration,
        }),
    icon: <PaymentMethodIcon type={"COUPON"} />,
    // If coupon is unlimited, it's usable.
    // If not, check that punches and punchesNeeded are set and disable if punches is less than punchesNeeded.
    disabled: method.unlimited
      ? false
      : method?.punches && method?.punchesNeeded
      ? parseInt(method.punches) < parseInt(method.punchesNeeded)
      : false,
    method,
  }));

  return options;
};

export type MixedPaymentOption = InternalPaymentOption | PaymentServiceOption;

export const createPaymentOptions = async (
  coupons?: coupon[],
  paymentService?: Core,
): Promise<MixedPaymentOption[]> => {
  const couponOptions = createCouponOptions(coupons);
  const paymentServiceOptions = await createPaymentServiceOptions(
    paymentService,
  );

  return [...couponOptions, ...paymentServiceOptions];
};

export const getPaymentOptionsQueryKey = ["paymentOptions"];

export const getPaymentOptionsQuery = (
  coupons?: coupon[],
  paymentService?: Core,
) => ({
  queryKey: getPaymentOptionsQueryKey,
  queryFn: () => createPaymentOptions(coupons, paymentService),
});
