import { AuthorizedService } from "@matchi/api";

interface ApplyValueCardVariables {
  token: string;
  customerCouponId: number;
  amount?: number;
}

export const getApplyValueCardMutationKey = (
  token: string,
  customerCouponId: number,
) => ["applyValueCard", token, customerCouponId];

export const getApplyValueCardMutation = () => ({
  mutationFn: ({ token, customerCouponId, amount }: ApplyValueCardVariables) =>
    AuthorizedService.applyValueCard(token, { customerCouponId, amount }),
});
