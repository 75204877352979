import FocusTrap from "focus-trap-react";
import { ReactNode } from "react";
import { IconButton } from "ui";

interface DialogProps {
  children: ReactNode;
  title?: ReactNode;
  open: boolean;
  onClose: () => void;
}

export const Dialog = ({ children, title, open, onClose }: DialogProps) => {
  if (!open) return null;

  return (
    <>
      <div className="fixed inset-0 z-50 bg-black/75" />
      <FocusTrap>
        <div className="fixed inset-0 z-50 flex items-end justify-center md:items-center md:px-4">
          <div className="border-default ring-theme-accent w-full rounded-t-xl bg-white outline-none ring-offset-1 transition duration-150 ease-in-out focus-visible:ring-2 md:max-w-[550px] md:rounded-xl">
            <header className="border-light flex h-16 items-center justify-between border-b px-4">
              <h1 className="truncate text-lg font-medium">{title}</h1>
              <IconButton onClick={() => onClose()} icon="XmarkSolid" />
            </header>
            <main>{children}</main>
          </div>
        </div>
      </FocusTrap>
    </>
  );
};
